import logo from './logo.svg';
import './App.css';
import {
    Admin, CreateButton,
    CustomRoutes,
    Datagrid, ExportButton, FilterButton,
    List,
    ListGuesser,
    Pagination,
    Resource,
    SearchInput, SelectColumnsButton,
    TextField, TopToolbar
} from "react-admin";
import {Route} from "react-router-dom"
import jsonServerProvider from "ra-data-json-server";

const baseUrl = "/api"
const token = window.localStorage.getItem("token")

class DataProvider {
    constructor(baseUrl, authToken) {
        this.baseUrl = baseUrl
        this.authToken = authToken
    }

    getList = async (resource, params) => {
        const {pagination, sort, filter, meta} = params
        const {page, perPage} = pagination
        const {field, order} = sort
        const {Search} = filter
        const results = await fetch(`${this.baseUrl}/${resource}/list?auth_token=${this.authToken}&page=${page}&records_per_page=${perPage}&sort=${field}&order=${order}${Search ? `&search=${Search}` : ""}`)
        const {success, result} = await results.json()
        const {interactions, count} = result
        return {
            data: interactions,
            total: count,
        }
    }
}

const dataProvider = new DataProvider(baseUrl, token)

function Settings() {
    const oldToken = localStorage.getItem("token")
    return (
        <div>
            <form>
                <label htmlFor="user">User</label>
                <input id="user" type="text"/>
                <label htmlFor="password">Password</label>
                <input id="password" type="password"/>
                <label htmlFor="token">Token</label>
                <input disabled id={"token"} type={"password"} value={oldToken}/>
                <button type={"submit"} onClick={async () => {
                    const user = document.getElementById("user").value
                    const password = document.getElementById("password").value
                    const response = await fetch(`${baseUrl}/login`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({name: user, password: password})
                    })
                    const {result} = await response.json()
                    const {token} = result
                    document.getElementById("token").value = token
                    localStorage.setItem("token", token)
                }}>login
                </button>
            </form>
        </div>
    )
}


const filters = [
    <SearchInput name={'Search'} source={"q"} alwaysOn/>
]

const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton/>
        <CreateButton/>
        <ExportButton maxResults={50000}/>
    </TopToolbar>
);

function InteractionList() {
    return (
        <List actions={<ListActions/>} filters={filters} pagination={(<Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 5000]} />)}>
            <Datagrid>
                <TextField source="DateCreated"/>
                <TextField source="Accusation"/>
                <TextField source="Response"/>
                <TextField source="IntermediateResponse"/>
            </Datagrid>
        </List>
    )
}


function App() {
    return (
        <Admin dataProvider={dataProvider}>
            <Resource name="interactions" list={<InteractionList/>}/>
            <CustomRoutes>
                <Route path={"/settings"} element={<Settings/>}/>
            </CustomRoutes>
        </Admin>
    );
}

export default App;
